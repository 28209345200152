






































































import { useUserState, useUserActions } from '@/store';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { ObjectId } from 'bson';

export default defineComponent({
  name: 'MDiscordChannel',
  props: {
    programId: {
      required: true,
      type: String,
      default: ''
    },
    programDoc: {
      require: true,
      type: Object,
      default: undefined
    },
    programName: {
      required: true,
      type: String,
      default: ''
    },
    discordData: {
      require: true,
      type: Object,
      default: undefined
    }
  },
  setup(props: any, ctx: any) {
    const { user }: any = useUserState(['user']);
    const { createDiscordChannels } = useUserActions(['createDiscordChannels']);
    const { addMembersToDiscordRole } = useUserActions(['addMembersToDiscordRole']);
    const { sendMessageInDiscord } = useUserActions(['sendMessageInDiscord']);
    const discordData = ref();
    const discordDataValue = computed({
      get: () => discordData.value || props.discordData,
      set: (newVal: any) => {
        discordData.value = newVal;
      }
    });
    const isJoining = ref(false);
    async function createChannel() {
      try {
        isJoining.value = true;
        const resp = await createDiscordChannels({
          program_id: props.programId.toString(),
          public_id: props?.programDoc?.data?.program_id ? props.programDoc?.data?._id : null,
          access_token: user.value.workspaceDiscordInfo.discordAccessToken,
          discord_id: user.value.workspaceDiscordInfo.discordId,
          user_type: 'organizer'
        });
        if (resp.data) {
          discordDataValue.value = resp.data.data;
          ctx.emit('channelGenerated');
          ctx.emit('fetchProgram', discordDataValue.value);
          await addMembersToDiscordRole({
            public_id: props?.programDoc?.data?.program_id ? props.programDoc?.data?._id : null,
            program_id: props.programId.toString(),
            discord_access_token: user.value.workspaceDiscordInfo.discordAccessToken,
            discord_user_name: user.value.workspaceDiscordInfo.discordUsername,
            discord_id: user.value.workspaceDiscordInfo.discordId
          });
        }
        isJoining.value = false;
      } catch (err) {
        console.log(err);
        isJoining.value = false;
      }
    }

    const isSendMessage = ref(false);
    const message = ref();
    const snackbarMessage = ref();
    const snackbar = ref(false);
    async function sendMessage() {
      try {
        isSendMessage.value = true;
        await addMembersToDiscordRole({
          public_id: props?.programDoc?.data?.program_id ? props.programDoc?.data?._id : null,
          program_id: props.programId.toString(),
          discord_access_token: user.value.workspaceDiscordInfo.discordAccessToken,
          discord_user_name: user.value.workspaceDiscordInfo.discordUsername,
          discord_id: user.value.workspaceDiscordInfo.discordId
        });
        await sendMessageInDiscord({
          channel_id: discordDataValue?.value?.channel?.id,
          access_token: user.value.workspaceDiscordInfo.discordAccessToken,
          message: message.value
        });
        message.value = '';
        isSendMessage.value = false;
        snackbar.value = true;
        snackbarMessage.value = 'Message sent Successfully';
      } catch (err) {
        console.log(err);
        isSendMessage.value = false;
      }
    }

    return {
      discordDataValue,
      isSendMessage,
      message,
      snackbar,
      snackbarMessage,
      sendMessage,
      createChannel,
      isJoining
    };
  }
});
